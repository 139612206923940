import '../sass/ToggleMenu.scss'

interface Props{
  toggleMenu: () => void
  breakPoint?: '768px' | '1000px'
}

const ToggleMenu = ({ toggleMenu, breakPoint = '768px' } : Props) => {
  return (
    <div onClick={toggleMenu} className={breakPoint === '768px' ? 'toggle-menu hide-pc' : 'toggle-menu hide-pc-1000'}>
      <div />
      <div />
      <div />
    </div>
  )
}
export default ToggleMenu
