// components/LandingHeader.tsx
'use client'

import Image from 'next/image'
import '../../sass/landing/LandingHeader.scss'
import { comfortaa, inter } from '@/lib/fonts'
import ToggleMenu from '../ToggleMenu'
import { useState } from 'react'
import { Link } from '@/i18n/routing'
import { useTranslations } from 'next-intl'
import LanguageSelector from './HeaderLanguageSelector'

interface Props {
  isBookingLanding?: boolean
}

const LandingHeader = ({ isBookingLanding = false }: Props) => {
  const t = useTranslations('LandingHeader')

  const bookingLandingLinks = [
    { label: t('links.serviceReservations'), anchor: '/booking-landing#bookings' },
    { label: t('links.digitalMenu'), anchor: '/booking-landing#menu' },
    { label: t('links.pricing'), anchor: '/booking-landing#pricing' },
    { label: t('links.faq'), anchor: '/booking-landing#faq' },
    { label: t('links.blog'), anchor: '/blog/1' }
  ]

  const homeLinks = [
    { label: t('links.digitalMenu'), anchor: '/#menu' },
    { label: t('links.serviceReservations'), anchor: '/#bookings' },
    { label: t('links.pricing'), anchor: '/#pricing' },
    { label: t('links.faq'), anchor: '/#faq' },
    { label: t('links.blog'), anchor: '/blog/1' }
  ]

  const links = isBookingLanding ? bookingLandingLinks : homeLinks

  const [showMenu, setShowMenu] = useState(false)

  const handleLinkClick = () => {
    setShowMenu(false)
  }

  return (
    <header className='landing-header'>
      <Link href='/' className='link-logo'>
        <Image
          src='/img/barmanager-logo.svg'
          alt={t('altLogo')} // Usa la traducción para el texto alternativo
          height={200}
          width={600}
        />
      </Link>

      <div style={{ marginRight: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
        <LanguageSelector hidePc />
        <ToggleMenu breakPoint='1000px' toggleMenu={() => setShowMenu(true)} />
      </div>

      <nav className={showMenu ? 'show-menu' : ''}>
        <ul>
          <Image
            className='sidebar-logo hide-pc-1000'
            src='/img/logo-alone.svg'
            alt={t('altSidebarLogo')} // Usa la traducción para el texto alternativo
            height={200}
            width={600}
          />
          {links.map((link, index) => (
            <li key={index}>
              <div onClick={() => { setShowMenu(false) }}>
                <Link prefetch={link.anchor !== '/blog/1'} style={comfortaa.style} href={link.anchor} passHref>
                  {link.label} {/* Usa la etiqueta traducida */}
                </Link>
              </div>
            </li>
          ))}
        </ul>
        <LanguageSelector hideMb />
        <Link
          style={inter.style}
          className='login-btn-div'
          href='/login'
          onClick={handleLinkClick}
        >
          {t('login')} {/* Usa la traducción para el botón de Login */}
        </Link>
      </nav>
      {showMenu &&
        <div
          className='stopper'
          onClick={(e) => {
            e.stopPropagation()
            setShowMenu(false)
          }}
        />}
    </header>
  )
}

export default LandingHeader
