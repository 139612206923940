'use client'

import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import Cookies from 'js-cookie'
import { Locale, routing } from '@/i18n/routing'

const useLanguageSwitcher = () => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const currentLocale = pathname.split('/')[1] as Locale

  const changeLanguage = (lng: Locale) => {
    if (lng === currentLocale) return // Evita recargar si el idioma es el actual

    // Establece la cookie con el locale seleccionado
    Cookies.set('NEXT_LOCALE', lng, { expires: 365 }) // Expira en 1 año

    // Construye la nueva ruta con el locale seleccionado
    let newPath = pathname

    // Si la ruta ya tiene un locale, reemplázalo
    const pathSegments = pathname.split('/').filter(Boolean) // Filtra segmentos vacíos
    if (routing.locales.includes(pathSegments[0] as Locale)) {
      pathSegments[0] = lng
      newPath = '/' + pathSegments.join('/')
    } else {
      // Si no tiene locale, añade el locale al inicio
      newPath = `/${lng}${pathname}`
    }

    // Reconstruye los parámetros de búsqueda si existen
    const queryString = searchParams.toString()
    if (queryString) {
      newPath += `?${queryString}`
    }

    // Navega a la nueva ruta
    router.push(newPath)
    console.log('cookie puesta')
  }

  return { changeLanguage, currentLocale }
}

export default useLanguageSwitcher
