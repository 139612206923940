// components/LandingFooter.tsx
'use client'

import Image from 'next/image'
import '../../sass/landing/Footer.scss'
import { Link } from '@/i18n/routing' // Asegúrate de que este Link es compatible
import { barlowCondensed } from '@/lib/fonts'
import { useTranslations } from 'next-intl'
import useLanguageSwitcher from '../widgets/settings/useLanguageSwitcher'

const LandingFooter = () => {
  const t = useTranslations('LandingFooter')
  const { changeLanguage } = useLanguageSwitcher()

  return (
    <footer style={barlowCondensed.style} className='landing-footer'>
      <div className='img-container'>
        <Image src='/img/barmanager-logo.svg' alt='logo barmanager.app' height={200} width={600} />
      </div>

      <ul>
        <li>
          <a href='mailto:info@barmanager.app'>
            {t('contact')}
          </a>
        </li>
        <li>
          <Link prefetch={false} href='/privacy'>{t('privacyPolicy')}</Link>
        </li>
        {/* Selector de Idioma Simplificado */}
        <li className='language-selector'>
          <span
            onClick={() => changeLanguage('es')}
            style={{ cursor: 'pointer', marginRight: '8px', color: '#374151' }}
            aria-label='Cambiar a Español'
          >
            Español
          </span>
          |
          <span
            onClick={() => changeLanguage('en')}
            style={{ cursor: 'pointer', marginLeft: '8px', color: '#374151' }}
            aria-label='Change to English'
          >
            English
          </span>
        </li>
      </ul>

      <div className='footer-text'>
        {t('footerText1')}
        <br />
        {t('footerText2')} <Link href='https://trivify.es'>{t('footerText3')}</Link>.
      </div>
    </footer>
  )
}

export default LandingFooter
