// src/i18n/routing.ts
import { defineRouting } from 'next-intl/routing'
import { createNavigation } from 'next-intl/navigation'

export type Locale = 'en' | 'es'

export const routing = defineRouting({
  locales: ['en', 'es'] as const, // 'as const' para inferir literales
  defaultLocale: 'es'
})

// Wrappers para los APIs de navegación de Next.js
export const { Link, redirect, usePathname, useRouter } = createNavigation(routing)
